body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.GameModal_main__Sf5cY .MuiModal-root .css-79ws1d-MuiModal-root{
  height: 100vh !important;
  background-image: url("/public/assets/bg1.jpg") !important;
}


.swiper {
  width: 100%;
  height: 100%;
 
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  
  height: 12rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}


@media only screen and (max-width:524px){
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    
    height: 15rem !important;
  }
}

