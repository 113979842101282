.container{
    display: flex;
    flex-direction: column;
    
    width: 100%;
    
}

.animation_div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.animation{
  /* background-color: black; */
  height: 32rem;
  width: 32rem;
}


.games{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.5rem;
    width: 100%;
    /* overflow: hidden; */
}

/* .game_card{
    background-color: black;
    text-align: center;
    border-radius: 20px;
} */

.title_div{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: white;
}

.img{
  width: 3rem;
  height: 3rem;
}

.title{
  text-align: center;
}

.game_img{
    width:100%;
    height:17rem;
    border-radius: 10px;
    background-color: black;
}

.card_container{
    /* background-color: black; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 15px;
    padding:0;
    margin: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    box-shadow: rgb(251 245 245 / 29%) 0px 2px 8px 0px;
    z-index: 100;
    overflow: hidden;
    /* object-fit: cover; */
    }

  .card_title {
    font-size: 1rem;
    color: var(--white);
    padding: 0.5rem;
    margin: 0;
    /* background-color: black; */
    color: rgb(252, 230, 32);
    display: flex;
    align-items: center;
    text-align: center;
    /* justify-content: center; */
    /* height: 50%; */
    /* width: 200px; */
    color: white;
  }

  .button{
    width: 50%;
    height: 50%;
    border: none;
   
    color: white;
    padding: 0.25rem;
    font-size: 1rem;
    cursor: pointer;
    /* color: black; */
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    background-image: linear-gradient(
      0deg,
      rgba(94, 58, 238, 1) 0%,
      rgba(197, 107, 240, 1) 100%
    );
   
  }
  
  .new{
    display: flex;
    justify-content: left;
    height: 3rem;
    /* padding: 0.5rem; */
    /* text-align: center; */
    color: black; /* background-color: rgb(252, 230, 32); */
    position: absolute;
    
    left: -5px;
    top: -5px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .detail{
    width: 100%;
    /* display: none; */
    opacity: 0;
    /* padding-bottom: 10px; */
    position: absolute;
    
    top: 70%;
    height: 30%;
    
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: left;
    /* text-align: center; */
    /* background: rgb(129,38,162);
    background: linear-gradient(78deg, rgba(129,38,162,1) 0%, rgba(99,9,121,1) 49%, rgba(175,54,177,1) 100%); */
    /* color: black; */
   background-color: black;
  
   
  }

  .detail p{
    text-align: center;
    width: 100%;
  
    
  }


  .card_container:hover img.game_img {
    transform: translateY(-20%);
    object-fit: fill;
    width: 100%;
    
  }

  
  

  .game_img:hover +  .detail{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    opacity: 1;
  }


  .detail:hover{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    opacity: 1;
  }

  

  
  

  @media only screen and (max-width:1028px){
    .games{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
    }

  }

  @media only screen and (max-width:824px){
    .games{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        
    }
    .game_img{
      height:15rem;
    }
  }

  
  @media only screen and (max-width:524px){
    .games{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        width: 100%;
    }

    .game_img{
      height:15rem;
    }

    .detail{
      opacity: 1;
    }

    
  }