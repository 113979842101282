.container {
    /* width: 100%; */
    min-height: 100vh;
    height: 100%;
    position: relative;
    background-color: black;
    background-image: url('/public/assets/bg1.jpg');
    /* background-image: url('https://img.freepik.com/premium-vector/abstract-neon-with-elegant-dark-background-gaming-stage-wallpaper_107575-245.jpg'); */
    /* background-color: blue; */
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
    margin: auto;
    gap: 2rem;
    color: white;
    background-color: black;
    
}

.animation{
    background-color: black;
    height: 100vh;
    width: 100vw;
    /* backdrop-filter: blur(5px); */
  }
/* 
.img{
    position: relative;
} */

/* .sub_container{
    position: absolute;
    z-index: 99;
} */

.header{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width:524px){
    .container{
        width: 100%;
    }
}



@media only screen and (max-width:375px){
    .container{
        width: 100%;
    }
}