
.container{
    color: rgb(30, 37, 57);
    font-family: sans-serif;
    /* display: flex; */
    /* justify-content: center; */
    margin-bottom: 10rem;
    position: absolute;
    top: 1rem;
    z-index: 99;
    width: 100%;
    /* overflow: scroll; */
    /* display: flex; */
    /* flex-direction: center; */
    /* justify-content: center; */
    /* align-items: center; */
}
  
  .container .box {
    padding: 0.5rem;
    /* width:90%; */
    /* background-color: rgba(30, 38, 68, 0.074); */
    color: white;
    border: 1px solid rgba(165, 233, 236, 0.222);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(20px);
    border-radius: 0.7rem;
    transition: all ease 0.3s;
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    width: 5%;
    color: white;
    
      /* From https://css.glass */
background: rgba(255, 255, 255, 0.1);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.2px);
-webkit-backdrop-filter: blur(5.2px);
border: 1px solid rgba(255, 255, 255, 0.26);
    /* overflow: scroll */
  }
  
  .container .box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    z-index: 99;
  }
  
  .container .box:hover {
    box-shadow: 0px 0px 20px 1px #ffbb763f;
    border: 1px solid rgba(255, 255, 255, 0.454);
  }


  .icon{
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 5px 2px 5px 2px;
    cursor: pointer;
    color: white;
    /* background-color: rgba(249, 137, 225, 0.724);
    border-radius: 10px; */
  }

  .icon p{
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  .icon_img{
    width: 60%;
    opacity: 1;
    border-radius: 50%;
  }

  /* .icon_img:hover{
    animation: bounce 2s infinite;
  } */

  .img_active{
    animation: bounce 2s infinite;
  }

  .active{
    opacity: 1;
    color: rgb(252, 230, 32);
    font-weight: 700;
    /* animation: bounce 2s infinite; */
    /* border: 1px solid yellow; */
  }


  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-8px);
    }
  }


  @media only screen and (max-width:676px){
    .container .box {
      padding: 0.5rem;
      width:2.5rem;
      top: 10%;
      gap: 1rem;
    }

  }