.container{
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: black;
    z-index: 0;
    display: flex;
    justify-content: center;

}

.sub_container{
    color: white;
    padding: 1rem;
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.button {
    --purple: #A862FF; /* Define purple color variable */
    font-size: 1rem;
    padding: 0.7em 1.5em;
    letter-spacing: 0.06em;
    position: relative;
    font-family: inherit;
    border-radius: 0.6em;
    overflow: hidden;
    transition: all 0.3s;
    line-height: 1.4em;
    border: 2px solid var(--purple);
    background: linear-gradient(to right, rgba(168, 98, 255, 0.1) 1%, transparent 40%, transparent 60%, rgba(168, 98, 255, 0.1) 100%);
    color: var(--purple);
    box-shadow: inset 0 0 10px rgba(168, 98, 255, 0.4), 0 0 9px 3px rgba(168, 98, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    /* width: 10rem; */
  }

  .button img{
    height: 1.5rem;
    width: 1.5rem;
  }
  
  .button:hover {
    color: #CAB6FF; /* Lighter purple color for hover */
    box-shadow: inset 0 0 10px rgba(168, 98, 255, 0.6), 0 0 9px 3px rgba(168, 98, 255, 0.2);
  }
  
  .button:before {
    content: "";
    position: absolute;
    left: -4em;
    width: 4em;
    height: 100%;
    top: 0;
    transition: transform .4s ease-in-out;
    background: linear-gradient(to right, transparent 1%, rgba(168, 98, 255, 0.1) 40%, rgba(168, 98, 255, 0.1) 60%, transparent 100%);
  }

  .active{
    color: white;
    text-shadow:
    0 0 7px purple,
    0 0 10px purple,
    0 0 21px purple,
    0 0 42px purple,
    0 0 60px pink,
    0 0 80px pink,
    0 0 102px pink,
    0 0 100px pink;
  }
  
  .button:hover:before {
    transform: translateX(15em);
  }

  .buttons{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }


  @media only screen and (max-width:756px){
    .buttons{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }
  }




  