.container {
    color: rgb(30, 37, 57);
    font-family: sans-serif;
    width: 100%;
    display: flex;
  }
  
  
  .container .box {
    /* padding: 1rem; */
    width:90%;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.222);
    /* -webkit-backdrop-filter: blur(30px); */
    backdrop-filter: blur(20px);
    border-radius: 0.7rem;
    transition: all ease 0.3s;
    margin-left: 6%;

      /* From https://css.glass */
background: rgba(255, 255, 255, 0.1);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.2px);
-webkit-backdrop-filter: blur(5.2px);
border: 1px solid rgba(255, 255, 255, 0.26);


    /* background-color: #fff; */
    
  }
  
  .container .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  
  .container .box:hover {
    box-shadow: 0px 0px 20px 1px #ffbb763f;
    border: 1px solid rgba(255, 255, 255, 0.454);
  }

  .logo_beg{

  }

  .logo{
    height: 5rem;
    display: flex;
    width: 13rem;
    /* justify-content: center; */
    /* margin: auto; */

  }

  .neonText {
    color: white;
    text-shadow:
      0 0 7px purple,
      0 0 10px purple,
      0 0 21px purple,
      0 0 42px purple,
      0 0 60px pink,
      0 0 80px pink,
      0 0 102px pink,
      0 0 100px pink;
  }

  @media only screen and (max-width:676px){
    .container .box {
      padding: 1rem;
      width:100%;
      margin-left: 8%;
    }
  }
  