.container{
    color: rgb(30, 37, 57);
    font-family: sans-serif;
    width: 100%;
    display: flex;
    /* justify-content: center; */
    min-height: 100vh;
    margin-bottom: 10rem;
    /* position: absolute; */
}
  
  .container .box {
    /* padding: 1rem; */
    width:90%;
    /* background-color: rgba(30, 38, 68, 0.074); */
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.222);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(20px);
    border-radius: 0.7rem;
    transition: all ease 0.3s;
    display: flex;
    justify-content: space-between;

  /* From https://css.glass */
background: rgba(255, 255, 255, 0.1);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.2px);
-webkit-backdrop-filter: blur(5.2px);
border: 1px solid rgba(255, 255, 255, 0.26);
  }
  
  .container .box {
    display: flex;
    justify-content: space-between;
    margin-left: 6%;
    padding: 1rem;
  }
  
  .container .box:hover {
    box-shadow: 0px 0px 20px 1px #ffbb763f;
    border: 1px solid rgba(255, 255, 255, 0.454);
  }

  @media only screen and (max-width:676px){
    .container .box {
      padding: 1rem;
      width:90%;
      margin-left: 8%;
    }

    .container{
      margin-bottom: 15rem;
    }
  }




























































  